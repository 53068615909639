import { Branding } from "./components/Branding";
import { ComingSoon } from "./components/ComingSoon";

function App() {
  return (
    <div className="App p-5 flex flex-col h-screen items-center justify-center">
      <div>
        <Branding />
        <ComingSoon />
      </div>
    </div>
  );
}

export default App;
