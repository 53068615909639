export function Branding({}) {
  return (
    <section className="mx-auto">
      <div className="flex w-1/2 m-auto text-center justify-center align-center">
        <div className="bg-hero-image bg-contain bg-no-repeat bg-center h-[250px] w-[250px] min-w-[250px] sm:h-[350px] sm:w-[350px] sm:min-w-[350px]" />
        {/* <div className="bg-logo bg-contain bg-no-repeat bg-center h-[150px] w-[150px] min-w-[150px] sm:h-[250px] sm:w-[250px] sm:min-w-[250px]" /> */}
      </div>
    </section>
  );
}
